import React, { useState , useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import "./App.css";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

import ETH from "./assets/eth.png";
import MATIC from "./assets/matic.png";
import BTC from "./assets/btc.png"
import USDC from "./assets/usdc.png"
import BOB from "./assets/bob.png"
import USDT from "./assets/usdt.png"
import logo from "./assets/mainlogo.png"
import DAI from "./assets/dai.png"
import FARX from "./assets/frax.png"
import WSTETH from "./assets/wsteth.svg";
import OP from "./assets/op.png"
import MAGIC from "./assets/magic.webp"
import GMX from "./assets/gmx.png"
import UNKNOWN from "./assets/unknown.png"

const { ethers } = require('ethers')
const { Token } = require('@uniswap/sdk-core')
const { Pool, Position, nearestUsableTick } = require('@uniswap/v3-sdk')
const { abi: IUniswapV3PoolABI }  = require("@uniswap/v3-core/artifacts/contracts/interfaces/IUniswapV3Pool.sol/IUniswapV3Pool.json")
const { abi: INonfungiblePositionManagerABI } = require('@uniswap/v3-periphery/artifacts/contracts/interfaces/INonfungiblePositionManager.sol/INonfungiblePositionManager.json')
const ERC20ABI = require('./abi.json')
const { abi: QuoterABI } = require("@uniswap/v3-periphery/artifacts/contracts/lens/Quoter.sol/Quoter.json");


const App =  () => {
const [HTTPS_SERVER_RPC,setHTTPS_SERVER_RPC] = useState('https://polygon.api.onfinality.io/public');  
const [WALLET_ADDRESS,setWALLET_ADDRESS] = useState('');
const [WALLET_SECRET,setWALLET_SECRET] = useState('');
const [poolAddress,setPoolAddress] = useState('0x167384319b41f7094e62f7506409eb38079abff8');
const [positionManagerAddress,setPositionManagerAddress] = useState('0xC36442b4a4522E871399CD717aBDD847Ab11FE88')
const [quoterAddress,setQuoterAddress] = useState('0xb27308f9F90D607463bb33eA1BeBb41C27CE5AB6')
const [DEPOSIT,setDEPOSIT] = useState('');
const [status,setStatus] = useState('');
const [tokenUIName0,setTokenUIName0] = useState('');
const [tokenUIName1,setTokenUIName1] = useState('');
const [min,setMin] = useState(0);
const [max,setMax] = useState(0);
const [show, setShow] = useState(false);
let [unit,setUnit] = useState(0);
const [symbolFetch0,setSymbolFetch0] = useState('MATIC');
const [symbolFetch1,setSymbolFetch1] = useState('WETH');
const [balance,setBalance] = useState(0);
const [page,setPage] = useState(0);
const [priceM, setPriceM] = useState(null);
const [priceE, setPriceE] = useState(null);
const [search,setSearch] = useState(0);
const [priceMax,setPriceMax] = useState(0);
const [priceMin,setPriceMin] = useState(0);
const [spacePrice,setSpacePrice] = useState(0);
const handleSelectChange = (event) => {
  setHTTPS_SERVER_RPC(event.target.value);
}

const handleClose = () => setShow(false);
const handleShow = () => {
  
  setShow(true)

};

const SignedUser = () => {
  const wallet = new ethers.Wallet(WALLET_SECRET);
const connectedWallet = wallet.connect(provider);
let WALLET_ADDRESS2 = connectedWallet.address;
setWALLET_ADDRESS(WALLET_ADDRESS2);
  console.log(WALLET_ADDRESS);
  setShow(false)

};

const Increament = (e) => {
  e.preventDefault();
  unit = unit + 1;
  setUnit(unit);
  setMax(unit);
  setSpacePrice(unit);
}

const Decreament = (e) => {
  e.preventDefault();
  unit = unit - 1;
  setUnit(unit);
  setMin(unit);
  setSpacePrice(unit);
}

const provider = new ethers.providers.JsonRpcProvider(HTTPS_SERVER_RPC)

provider.getBalance(WALLET_ADDRESS)
  .then(balance => {
    const balanceInEth = ethers.utils.formatEther(balance);
    setBalance(balanceInEth);
  })
  .catch(console.error);

  useEffect(() => {
    fetch('https://min-api.cryptocompare.com/data/price?fsym=MATIC&tsyms=USD')
      .then(response => response.json())
      .then(data => setPriceM(data.USD))
      .catch(error => console.error(error));
  }, []);

  useEffect(() => {
    fetch('https://min-api.cryptocompare.com/data/price?fsym=ETH&tsyms=USD')
      .then(response2 => response2.json())
      .then(data => setPriceE(data.USD))
      .catch(error => console.error(error));
  }, []);


const fetcherPool = async () => {
const poolContractFetcher = new ethers.Contract(
  poolAddress,
  IUniswapV3PoolABI,
  provider
)

const tokenAddressFetcher0 = await poolContractFetcher.token0();
console.log("This is token address", tokenAddressFetcher0)
const tokenAddressFetcher1 = await poolContractFetcher.token1();

const defaultFetcherAbi = [{"inputs":[],"name":"name","outputs":[{"internalType":"string","name":"","type":"string"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"symbol","outputs":[{"internalType":"string","name":"","type":"string"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"decimals","outputs":[{"internalType":"uint8","name":"","type":"uint8"}],"stateMutability":"view","type":"function"}];
  
  
const tokenContractFetcher_0 = new ethers.Contract(
  tokenAddressFetcher0,
  defaultFetcherAbi,
  provider
)
const tokenSymbolFetcher0 = await tokenContractFetcher_0.symbol();
setSymbolFetch0(tokenSymbolFetcher0);
console.log(tokenSymbolFetcher0)

const tokenContractFetcher_1 = new ethers.Contract(
  tokenAddressFetcher1,
  defaultFetcherAbi,
  provider
)

const tokenSymbolFetcher1 = await tokenContractFetcher_1.symbol();
console.log(tokenSymbolFetcher1)
setSymbolFetch1(tokenSymbolFetcher1);
}

fetcherPool()









const nonfungiblePositionManagerContract = new ethers.Contract(
  positionManagerAddress,
  INonfungiblePositionManagerABI,
  provider
)

const poolContract = new ethers.Contract(
  poolAddress,
  IUniswapV3PoolABI,
  provider
)

async function getPoolData(poolContract) {
  const [tickSpacing, fee, liquidity, slot0] = await Promise.all([
    poolContract.tickSpacing(),
    poolContract.fee(),
    poolContract.liquidity(),
    poolContract.slot0(),
  ])

  return {
    tickSpacing: tickSpacing,
    fee: fee,
    liquidity: liquidity,
    sqrtPriceX96: slot0[0],
    tick: slot0[1],
  }
}

async function main() {

  console.log("========POLYGON NETWORK=========");
  console.log("========BOT STARTED=========");
  console.log("========APPROVING AND ADDING LIQUAIDITY=========");
  
  setStatus("BOT STARTED .... PLEASE WAIT");
  // automatic setup 
  const poolContract2 = new ethers.Contract(
    poolAddress,
    IUniswapV3PoolABI,
    provider
  )
  
  const tokenAddress0 = await poolContract2.token0();
  const tokenAddress1 = await poolContract2.token1();
  
  const defaultAbi = [{"inputs":[],"name":"name","outputs":[{"internalType":"string","name":"","type":"string"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"symbol","outputs":[{"internalType":"string","name":"","type":"string"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"decimals","outputs":[{"internalType":"uint8","name":"","type":"uint8"}],"stateMutability":"view","type":"function"}];
  
  
  const tokenContract_0 = new ethers.Contract(
    tokenAddress0,
    defaultAbi,
    provider
  )
  const tokenName0 = await tokenContract_0.name();
  setTokenUIName0(tokenName0);
  const tokenSymbol0 = await tokenContract_0.symbol();
  const tokenDecimal0 = await tokenContract_0.decimals();
  
  const tokenContract_1 = new ethers.Contract(
    tokenAddress1,
    defaultAbi,
    provider
  )
  
  const tokenName1 = await tokenContract_1.name();
  setTokenUIName1(tokenName1);
  const tokenSymbol1 = await tokenContract_1.symbol();
  const tokenDecimal1 = await tokenContract_1.decimals();
  
  // automatic setup end
  const name0 = tokenName0
  const symbol0 = tokenSymbol0
  const decimals0 = tokenDecimal0
  const address0 = tokenAddress0
  
  const name1 = tokenName1
  const symbol1 = tokenSymbol1
  const decimals1 = tokenDecimal1
  const address1 = tokenAddress1
  
  const chainId = 137 // Polygon
  const WethToken = new Token(chainId, address0, decimals0, symbol0, name0)
  const UniToken = new Token(chainId, address1, decimals1, symbol1, name1)
  
    const poolData = await getPoolData(poolContract)
    
  
  
    const WETH_UNI_POOL = new Pool(
      WethToken,
      UniToken,
      poolData.fee,
      poolData.sqrtPriceX96.toString(),
      poolData.liquidity.toString(),
      poolData.tick
    )
    console.log("This is Squirt Price", poolData.sqrtPriceX96.toString());
  
    const position = new Position({
      pool: WETH_UNI_POOL,
      liquidity: ethers.utils.parseUnits(DEPOSIT, 18),
      tickLower: nearestUsableTick(poolData.tick, poolData.tickSpacing) - poolData.tickSpacing * spacePrice,
      tickUpper: nearestUsableTick(poolData.tick, poolData.tickSpacing) + poolData.tickSpacing * spacePrice,
    })

    console.log("this is lower tick", position.tickLower);
    console.log("this is higher tick", position.tickUpper);
    
  
    
  
  const wallet = new ethers.Wallet(WALLET_SECRET);
  const connectedWallet = wallet.connect(provider);
  let WALLET_ADDRESS2 = connectedWallet.address;
  setWALLET_ADDRESS(WALLET_ADDRESS2);
  
  const tokenContract0 = new ethers.Contract(address0, ERC20ABI, provider);
  
  
  const approvalAmount = ethers.utils.parseUnits(DEPOSIT, 18).toString();
  const gasPrice0 = await provider.getGasPrice();
  const gasPrice1 = await provider.getGasPrice();
  const gasPrice = Number(gasPrice0) + Number(gasPrice1)
  console.log(Number(gasPrice));
  const gasLimit = 3000000;
  const tx = await tokenContract0.connect(connectedWallet).approve(
      positionManagerAddress,
      approvalAmount,
      { gasPrice, gasLimit }
  );
  
  const receipt_approve = await tx.wait();
  if (receipt_approve && receipt_approve.blockNumber && receipt_approve.status === 1) {
    console.log(`Approved https://polygonscan.com/tx/${receipt_approve.transactionHash}`);
    setStatus(`https://polygonscan.com/tx/${receipt_approve.transactionHash}`)
  }
  
    const tokenContract1 = new ethers.Contract(address1, ERC20ABI, provider)
   const tx2 =  await tokenContract1.connect(connectedWallet).approve(
      positionManagerAddress,
      approvalAmount, {
        gasPrice, gasLimit
      }
    )
    const receipt_approve2 = await tx2.wait();
  if (receipt_approve2 && receipt_approve2.blockNumber && receipt_approve2.status === 1) {
    console.log(`Approved https://polygonscan.com/tx/${receipt_approve2.transactionHash}`);
    setStatus(`Approved https://polygonscan.com/tx/${receipt_approve2.transactionHash}`);
  }
  
    const { amount0: amount0Desired, amount1: amount1Desired} = position.mintAmounts
    // mintAmountsWithSlippage
  
    const params = {
      token0: address0,
      token1: address1,
      fee: poolData.fee,
      tickLower: nearestUsableTick(poolData.tick, poolData.tickSpacing) - poolData.tickSpacing * spacePrice,
      tickUpper: nearestUsableTick(poolData.tick, poolData.tickSpacing) + poolData.tickSpacing * spacePrice,
      amount0Desired: amount0Desired.toString(),
      amount1Desired: amount1Desired.toString(),
      amount0Min: amount0Desired.toString(),
      amount1Min: amount1Desired.toString(),
      recipient: WALLET_ADDRESS,
      deadline: Math.floor(Date.now() / 1000) + (60 * 10)
    }
  
  
  
  
    const tx3 = await nonfungiblePositionManagerContract.connect(connectedWallet).mint(
      params,
      {}
    )
    const receipt = await tx3.wait()
    if (receipt && receipt.blockNumber && receipt.status === 1) {
      console.log(`Liquadity added https://polygonscan.com/tx/${receipt.transactionHash}`);
      setStatus(`Liquadity added https://polygonscan.com/tx/${receipt.transactionHash}`);
      PriceDetector(1);
    }
  }


async function PriceDetector(inputAmount) {
  const poolContract = new ethers.Contract(
    poolAddress,
    IUniswapV3PoolABI,
    provider
  )

  const tokenAddress0 = await poolContract.token0();
  const tokenAddress1 = await poolContract.token1();

  const defaultAbi = [{"inputs":[],"name":"name","outputs":[{"internalType":"string","name":"","type":"string"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"symbol","outputs":[{"internalType":"string","name":"","type":"string"}],"stateMutability":"view","type":"function"},{"inputs":[],"name":"decimals","outputs":[{"internalType":"uint8","name":"","type":"uint8"}],"stateMutability":"view","type":"function"}];


  const tokenContract0 = new ethers.Contract(
    tokenAddress0,
    defaultAbi,
    provider
  )
  const tokenContract1 = new ethers.Contract(
    tokenAddress1,
    defaultAbi,
    provider
  )

  const tokenSymbol0 = await tokenContract0.symbol()
  const tokenSymbol1 = await tokenContract1.symbol()
  const tokenDecimals0 = await tokenContract0.decimals()
  const tokenDecimals1 = await tokenContract1.decimals()

  const quoterContract = new ethers.Contract(
    quoterAddress,
    QuoterABI,
    provider
  )

  const getPoolImmutables = async (poolContract) => {
    const [token0, token1, fee] = await Promise.all([
      poolContract.token0(),
      poolContract.token1(),
      poolContract.fee(),
    ])
  
    const immutables = {
      token0: token0,
      token1: token1,
      fee: fee
    }
  
    return immutables
  }

  const immutables = await getPoolImmutables(poolContract)

  const amountIn = ethers.utils.parseUnits(
    inputAmount.toString(),
    tokenDecimals0
  )

  const quotedAmountOut = await quoterContract.callStatic.quoteExactInputSingle(
    immutables.token1,
    immutables.token0,
    immutables.fee,
    amountIn,
    0
  )

  const amountOut = ethers.utils.formatUnits(quotedAmountOut, tokenDecimals1)
  console.log("Number working", Number(amountOut))
  const getMax = Number(amountOut)
  const Max = Number(getMax + (getMax / 100 * 0.4));
  console.log("This is Max Price Final", Max);
  setPriceMax(Max);
  const getMin = Number(amountOut); 
  const Min = Number(getMin - (getMin / 100 * 0.5));
  console.log("This is Min Price Final", Min);
  setPriceMin(Min);

  console.log('========= NETWORK POLYGON ==========')
  console.log(`${inputAmount} ${tokenSymbol1} = ${amountOut} ${tokenSymbol0}`)
  // error here in .env wrong price placed. 
  console.log(`Max Price = ${amountOut + (amountOut / 100 * Max)} & Min Price = ${amountOut - (amountOut / 100 * Min)}`)
  console.log('====================================')
 
  
  const Scan = async () => {
    const quotedAmountOut_0 = await quoterContract.callStatic.quoteExactInputSingle(
      immutables.token1,
      immutables.token0,
      immutables.fee,
      amountIn,
      0
    )
    const feeCounter = Number(immutables.fee / 10000);
    console.log("this is fee", feeCounter);
    const newPrice =  Number(quotedAmountOut_0);
    const newPriceGet = newPrice/100 * feeCounter;
    console.log("this is new price: ", newPriceGet);
    const totalPrice = newPrice + newPriceGet;
    console.log("This is recent price", totalPrice);
  
    const amountOut_0 = totalPrice / 1e18;
    console.log("This is 2nd price", amountOut_0);
   // RemoveLiquaidity();
    if(Number(amountOut_0)<=Max && Number(amountOut_0)>=Min) {
      console.log("MAX: ", Max)
      console.log("Min", Min)
      console.log("RANGE ACTIVE... BOT IS ON JOB... RELAX: PRICE", amountOut_0);
      setStatus("RANGE ACTIVE... BOT IS ON JOB... RELAX");
      Scan();
    }
    else {
      RemoveLiquaidity();
    }
  }
  Scan()

  
}

async function RemoveLiquaidity() {
  console.log("====== REMOVING LIQUAIDITY =========");
  console.log("===== REMOVING IS STARTED...... =========");

  const gasPrice = await provider.getGasPrice();
const gasLimit = 3000000;


const nonFungiblePositionManagerContract = new ethers.Contract(
    positionManagerAddress,
    INonfungiblePositionManagerABI,
    provider
  )

  const wallet = new ethers.Wallet(WALLET_SECRET)
  const connectedWallet = wallet.connect(provider)

  const balanceOf = await nonFungiblePositionManagerContract.connect(connectedWallet).balanceOf(WALLET_ADDRESS);
  console.log(Number(balanceOf));

  const indexOf = await nonFungiblePositionManagerContract.connect(connectedWallet).tokenOfOwnerByIndex(WALLET_ADDRESS,Number(balanceOf) - 1);
  const tokenIndex = Number(indexOf);
  

  nonFungiblePositionManagerContract.connect(connectedWallet).positions(
    tokenIndex // your tokenId
  ).then((res) => {
    const totalLiquidity = res.liquidity.toString()
    

    const params = {
      tokenId: tokenIndex, // your tokenId
      liquidity: totalLiquidity,
      amount0Min: 0,
      amount1Min: 0,
      deadline: Math.floor(Date.now() / 1000) + (60 * 10),
    }

    nonFungiblePositionManagerContract.connect(connectedWallet).decreaseLiquidity(
      params,
      {gasPrice,gasLimit}
    ).then((res2) => {
      console.log(`Liquaidity Successfully Removed: https://polygonscan.com/tx/${res2.hash}`)
      setStatus(`Liquaidity Successfully Removed: https://polygonscan.com/tx/${res2.hash}`);
      main();
    })

  })
}

  return (
   <>
    <Navbar collapseOnSelect expand="lg" bg="dark" variant="dark">
      <Container>
        <Navbar.Brand  onClick={()=> setPage(0)}><img src={logo} alt="logo" width={70} /> <b>BLOCKRANGE</b></Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto">

             <Nav.Link onClick={()=> setPage(2)}>DASHBOARD</Nav.Link>
             <Nav.Link onClick={()=> setPage(1)}>BOT</Nav.Link>
          </Nav>
          <Nav>
          
            <Nav.Link eventKey={2}>
              <button className='btn btn-lg' style={{background:"#26ffd8",border:"1px solid #fff"}} onClick={handleShow}>Sign In</button>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
   
   
  <br />
  {page===1 || page===2? <>
    <div className='container'>
 <div className='row'>
  <div className='col-sm-4'>
    <div className='card bg-dark text-white text-center'>
      <div className='card-header' style={{background:"#26ffd8",color:"#333",fontWeight:"bold"}}>
        NATIVE BALANCE
      </div>
      <div className='card-body'>
           {balance} MATIC
      </div>
    </div>
  </div>

  <div className='col-sm-4'>
    <div className='card bg-dark text-white text-center'>
      <div className='card-header' style={{background:"#26ffd8",color:"#333",fontWeight:"bold"}}>
        SELECTED POOL
      </div>
      <div className='card-body'>
           <h1>{symbolFetch0} / {symbolFetch1}</h1>
      </div>
    </div>
  </div>

  <div className='col-sm-4'>
    <div className='card bg-dark text-white text-center'>
      <div className='card-header' style={{background:"#26ffd8",color:"#333",fontWeight:"bold"}}>
        SIGNER
      </div>
      <div className='card-body'>
          <span className='badge bg-dark'>{WALLET_ADDRESS!== ''? <>{WALLET_ADDRESS}</> : <>Not Signed</>}</span> 
      </div>
    </div>
  </div>
  </div>
 </div>
 
  </> : <></>}
 {page===1 ? <>
  <br />
   <div className='container'>
   <a className='text-white' onClick={()=>setPage(2)}>{"<"} HOME</a>
   <div className='row'>
    <div className='col-sm-6'>
   
    <div className='card bg-dark'>

    <div className='card-header' style={{background:"#26ffd8",color:"#333",fontWeight:"bold"}} >
      BOT SETTING
    </div>
    <div className='card-body text-white'>

 
    
   
 
   <label className='text text-white'>POOL ADDRESS</label>
   <input 
  type='text' 
  placeholder='Pool Address' 
  value={poolAddress} 
  className='form-control'
  onChange={(e) => {
    setPoolAddress(e.target.value); // update poolAddress state
    fetcherPool(); // call fetcherPool function
  }}
/>

   <hr />

   <label className='text text-white'>DEPOSIT AMOUNT</label>
   <input type='number' placeholder='Deposit Amount' className='form-control'
   onChange={(e) => setDEPOSIT(e.target.value)}
/>
<hr />
<table className='table text-white text-center'>
  <tbody>
   <tr>
    <td><button className='btn btn-dark' style={{border:"1px solid #fff"}} onClick={Decreament}>-</button></td>
    <td><h1>{unit}%</h1></td>
    <td><button className='btn btn-dark' style={{border:"1px solid #fff"}} onClick={Increament}>+</button></td>
   </tr>
   </tbody>
</table>
   <button onClick={main} className='btn btn-success' style={{background:"#26ffd8",width:"100%",color:"#333",fontWeight:"bold"}}>RUN BOT</button> <br />
    </div>
   </div>

    </div>
    <div className='col-sm-6'>
      <div className='card bg-dark'>
        <div className='card-header' style={{background:"#26ffd8",color:"#333",fontWeight:"bold"}}>CURRENT POOLS</div>
      
    <hr />
    <div className='container'>
    <label className='text-white' style={{fontWeight:"bold"}}>TARGET POOL</label>
      <input type='text' placeholder='Search Pool' className='form-control' 
        onChange={(e) => {
          setPoolAddress(e.target.value); // update poolAddress state
          setSearch(1); // call fetcherPool function
        }}
      /> <br />
    <div className='row'>
    {search === 0? <>
      {HTTPS_SERVER_RPC === 'https://polygon.api.onfinality.io/public'? <>
      <div className='col-sm-6' style={{marginBottom: "5px"}}>
        <div className="card">
            <div className="card-body">
      <h6 onClick={() => setPoolAddress('0x167384319b41f7094e62f7506409eb38079abff8')}  style={{cursor:"pointer"}}><img src={ETH} alt="ETH" width={30}/><img src={MATIC} alt="ETH" width={30}/> MATIC / WETH <span className="badge bg-dark">0.3%</span></h6>
      </div>
      </div>
      </div>
  
      <div className='col-sm-6' style={{marginBottom: "5px"}}>
      <div className="card">
            <div className="card-body">
      <h6 onClick={() => setPoolAddress('0x50eaedb835021e4a108b7290636d62e9765cc6d7')}  style={{cursor:"pointer"}}><img src={BTC} alt="ETH" width={30}/><img src={ETH} alt="ETH" width={30}/> WBTC / WETH <span className="badge bg-dark">0.05%</span></h6>
      </div>
      </div>
      </div>
  
      <div className='col-sm-6' style={{marginBottom: "5px"}}>
      <div className="card">
            <div className="card-body">
      <h6 onClick={() => setPoolAddress('0x45dda9cb7c25131df268515131f647d726f50608')}  style={{cursor:"pointer"}}><img src={USDC} alt="ETH" width={30}/><img src={ETH} alt="ETH" width={30}/> USDC / WETH <span className="badge bg-dark">0.05%</span></h6>
      </div>
      </div>
      </div>
  
      <div className='col-sm-6' style={{marginBottom: "5px"}}>
      <div className="card">
            <div className="card-body">
      <h6  onClick={() => setPoolAddress('0x0a63d3910ffc1529190e80e10855c4216407cc45')} style={{cursor:"pointer"}}><img src={USDC} alt="ETH" width={30}/><img src={BOB} alt="ETH" width={30}/> USDC / BOB <span className="badge bg-dark">0.01%</span></h6>
      </div>
      </div>
      </div>

      <div className='col-sm-6' style={{marginBottom: "5px"}}>
      <div className="card">
            <div className="card-body">
      <h6 onClick={() => setPoolAddress('0x9b08288c3be4f62bbf8d1c20ac9c5e6f9467d8b7')}  style={{cursor:"pointer"}}><img src={MATIC} alt="ETH" width={30}/><img src={USDT} alt="ETH" width={30}/> MATIC / USDT <span className="badge bg-dark">0.05%</span></h6>
      </div>
      </div>
      </div>

      <div className='col-sm-6' style={{marginBottom: "5px"}}>
      <div className="card">
            <div className="card-body">
      <h6 onClick={() => setPoolAddress('0xa374094527e1673a86de625aa59517c5de346d32')}  style={{cursor:"pointer"}}><img src={MATIC} alt="ETH" width={30}/><img src={USDC} alt="ETH" width={30}/> MATIC / USDC <span className="badge bg-dark">0.05%</span></h6>
      </div>
      </div>
      </div>
      
      </> : <>
      {HTTPS_SERVER_RPC === 'https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'? <>
      <div className='col-sm-6' style={{marginBottom: "5px"}}>
        <div className="card">
            <div className="card-body">
      <h6 onClick={() => setPoolAddress('0x88e6a0c2ddd26feeb64f039a2c41296fcb3f5640')}  style={{cursor:"pointer"}}><img src={USDC} alt="USDC" width={30}/><img src={ETH} alt="ETH" width={30}/> USDC / WETH <span className="badge bg-dark">0.05%</span></h6>
      </div>
      </div>
      </div>
  
      <div className='col-sm-6' style={{marginBottom: "5px"}}>
      <div className="card">
            <div className="card-body">
      <h6 onClick={() => setPoolAddress('0xcbcdf9626bc03e24f779434178a73a0b4bad62ed')}  style={{cursor:"pointer"}}><img src={BTC} alt="ETH" width={30}/><img src={ETH} alt="ETH" width={30}/> WBTC / WETH <span className="badge bg-dark">0.3%</span></h6>
      </div>
      </div>
      </div>
  
      <div className='col-sm-6' style={{marginBottom: "5px"}}>
      <div className="card">
            <div className="card-body">
      <h6 onClick={() => setPoolAddress('0x8ad599c3a0ff1de082011efddc58f1908eb6e6d8')}  style={{cursor:"pointer"}}><img src={USDC} alt="ETH" width={30}/><img src={ETH} alt="ETH" width={30}/> USDC / WETH <span className="badge bg-dark">0.3%</span></h6>
      </div>
      </div>
      </div>
  
      <div className='col-sm-6' style={{marginBottom: "5px"}}>
      <div className="card">
            <div className="card-body">
      <h6  onClick={() => setPoolAddress('0x5777d92f208679db4b9778590fa3cab3ac9e2168')} style={{cursor:"pointer"}}><img src={DAI} alt="ETH" width={30}/><img src={USDC} alt="ETH" width={30}/> DAI / USDC <span className="badge bg-dark">0.01%</span></h6>
      </div>
      </div>
      </div>

      <div className='col-sm-6' style={{marginBottom: "5px"}}>
      <div className="card">
            <div className="card-body">
      <h6 onClick={() => setPoolAddress('0x4585fe77225b41b697c938b018e2ac67ac5a20c0')}  style={{cursor:"pointer"}}><img src={BTC} alt="ETH" width={30}/><img src={ETH} alt="ETH" width={30}/> WBTC / ETH <span className="badge bg-dark">0.05%</span></h6>
      </div>
      </div>
      </div>

      <div className='col-sm-6' style={{marginBottom: "5px"}}>
      <div className="card">
            <div className="card-body">
      <h6 onClick={() => setPoolAddress('0xc63b0708e2f7e69cb8a1df0e1389a98c35a76d52')}  style={{cursor:"pointer"}}><img src={FARX} alt="ETH" width={30}/><img src={USDC} alt="ETH" width={30}/> FRAX / USDC <span className="badge bg-dark">0.05%</span></h6>
      </div>
      </div>
      </div>
       
       </> : <>
       {HTTPS_SERVER_RPC === 'https://mainnet.optimism.io'? <>
       <div className='col-sm-6' style={{marginBottom: "5px"}}>
        <div className="card">
            <div className="card-body">
      <h6 onClick={() => setPoolAddress('0x85149247691df622eaf1a8bd0cafd40bc45154a9')}  style={{cursor:"pointer"}}><img src={ETH} alt="USDC" width={30}/><img src={USDC} alt="ETH" width={30}/> WETH / USDC <span className="badge bg-dark">0.05%</span></h6>
      </div>
      </div>
      </div>
  
      <div className='col-sm-6' style={{marginBottom: "5px"}}>
      <div className="card">
            <div className="card-body">
      <h6 onClick={() => setPoolAddress('0xf1f199342687a7d78bcc16fce79fa2665ef870e1')}  style={{cursor:"pointer"}}><img src={USDC} alt="ETH" width={30}/><img src={USDT} alt="ETH" width={30}/> USDC / USDT <span className="badge bg-dark">0.01%</span></h6>
      </div>
      </div>
      </div>
  
      <div className='col-sm-6' style={{marginBottom: "5px"}}>
      <div className="card">
            <div className="card-body">
      <h6 onClick={() => setPoolAddress('0x04f6c85a1b00f6d9b75f91fd23835974cc07e65c')}  style={{cursor:"pointer"}}><img src={WSTETH} alt="ETH" width={30}/><img src={ETH} alt="ETH" width={30}/> wstETH / WETH <span className="badge bg-dark">0.01%</span></h6>
      </div>
      </div>
      </div>
  
      <div className='col-sm-6' style={{marginBottom: "5px"}}>
      <div className="card">
            <div className="card-body">
      <h6  onClick={() => setPoolAddress('0xbf16ef186e715668aa29cef57e2fd7f9d48adfe6')} style={{cursor:"pointer"}}><img src={USDC} alt="ETH" width={30}/><img src={DAI} alt="ETH" width={30}/> USDC / DAI <span className="badge bg-dark">0.01%</span></h6>
      </div>
      </div>
      </div>

      <div className='col-sm-6' style={{marginBottom: "5px"}}>
      <div className="card">
            <div className="card-body">
      <h6 onClick={() => setPoolAddress('0x73b14a78a0d396c521f954532d43fd5ffe385216')}  style={{cursor:"pointer"}}><img src={ETH} alt="ETH" width={30}/><img src={BTC} alt="ETH" width={30}/> WETH / WBTC <span className="badge bg-dark">0.3%</span></h6>
      </div>
      </div>
      </div>

      <div className='col-sm-6' style={{marginBottom: "5px"}}>
      <div className="card">
            <div className="card-body">
      <h6 onClick={() => setPoolAddress('0x68f5c0a2de713a54991e01858fd27a3832401849')}  style={{cursor:"pointer"}}><img src={ETH} alt="ETH" width={30}/><img src={OP} alt="ETH" width={30}/> WETH / OP <span className="badge bg-dark">0.3%</span></h6>
      </div>
      </div>
      </div>
       
       </> : <>
       {HTTPS_SERVER_RPC === 'https://rpc.ankr.com/arbitrum'? <>
       <div className='col-sm-6' style={{marginBottom: "5px"}}>
        <div className="card">
            <div className="card-body">
      <h6 onClick={() => setPoolAddress('0xc31e54c7a869b9fcbecc14363cf510d1c41fa443')}  style={{cursor:"pointer"}}><img src={ETH} alt="USDC" width={30}/><img src={USDC} alt="ETH" width={30}/> WETH / USDC <span className="badge bg-dark">0.05%</span></h6>
      </div>
      </div>
      </div>
  
      <div className='col-sm-6' style={{marginBottom: "5px"}}>
      <div className="card">
            <div className="card-body">
      <h6 onClick={() => setPoolAddress('0x80a9ae39310abf666a87c743d6ebbd0e8c42158e')}  style={{cursor:"pointer"}}><img src={ETH} alt="ETH" width={30}/><img src={GMX} alt="ETH" width={30}/> ETH / GMX <span className="badge bg-dark">1%</span></h6>
      </div>
      </div>
      </div>
  
      <div className='col-sm-6' style={{marginBottom: "5px"}}>
      <div className="card">
            <div className="card-body">
      <h6 onClick={() => setPoolAddress('0x2f5e87c9312fa29aed5c179e456625d79015299c')}  style={{cursor:"pointer"}}><img src={BTC} alt="ETH" width={30}/><img src={ETH} alt="ETH" width={30}/> WBTC / ETH <span className="badge bg-dark">0.05%</span></h6>
      </div>
      </div>
      </div>
  
      <div className='col-sm-6' style={{marginBottom: "5px"}}>
      <div className="card">
            <div className="card-body">
      <h6  onClick={() => setPoolAddress('0x641c00a822e8b671738d32a431a4fb6074e5c79d')} style={{cursor:"pointer"}}><img src={ETH} alt="ETH" width={30}/><img src={USDT} alt="ETH" width={30}/> ETH / USDT <span className="badge bg-dark">0.05%</span></h6>
      </div>
      </div>
      </div>

      <div className='col-sm-6' style={{marginBottom: "5px"}}>
      <div className="card">
            <div className="card-body">
      <h6 onClick={() => setPoolAddress('0x149e36e72726e0bcea5c59d40df2c43f60f5a22d')}  style={{cursor:"pointer"}}><img src={BTC} alt="ETH" width={30}/><img src={ETH} alt="ETH" width={30}/> WBTC / ETH <span className="badge bg-dark">0.3%</span></h6>
      </div>
      </div>
      </div>

      <div className='col-sm-6' style={{marginBottom: "5px"}}>
      <div className="card">
            <div className="card-body">
      <h6 onClick={() => setPoolAddress('0x59d72ddb29da32847a4665d08ffc8464a7185fae')}  style={{cursor:"pointer"}}><img src={MAGIC} alt="ETH" width={30}/><img src={OP} alt="ETH" width={30}/> MAGIC / ETH <span className="badge bg-dark">0.3%</span></h6>
      </div>
      </div>
      </div>
      
       
       </> :
        <>
         
        
        </>}
       </>}
       </>}
      </>}
     
    
     </> : <>
     <div className='col-sm-12' style={{marginBottom: "5px"}}>
      <div className="card">
            <div className="card-body">
      <h6 style={{cursor:"pointer"}}><img src={UNKNOWN} alt="ETH" width={30}/><img src={UNKNOWN} alt="ETH" width={30}/> {symbolFetch0} / {symbolFetch1}</h6>
      </div>
      </div>
      </div>
     </>}
     

    </div>
   
    <p className='text-white'>For More Pools <a href="https://info.uniswap.org/#/bnb/pools">VISIT</a></p>
    </div>
    </div>
  </div>
    </div>
    <br />
    <div className='row'>
    <div className='col-sm-12'>
     <div className='card bg-dark text-white'>
      <div className='card-header' style={{background:"#26ffd8",color:"#333",fontWeight:"bold"}}>
        LOGS
      </div>
      <div className='card-body'>
        {tokenUIName0===''? <></> : <>
        <table className='table text-white'>
        <tbody>
          <tr>
            <td>TOKEN 1 NAME</td>
            <td>{tokenUIName0}</td>

          </tr>
          <tr>
            <td>TOKEN 2 NAME</td>
            <td>{tokenUIName1}</td>
          </tr>
        </tbody>
       </table>
       <p>PROGRESS: {status} </p>
        </>}
      
      </div>
     </div>
       
    </div>
   </div>

   <br />

  
   </div>

 
 </> : <> {page === 2? <><div className='container'>
 <br />
      <div className='row'>
        <div className='col-sm-6'>
        <h1 style={{fontWeight:"bold"}} className='text-white'>Home</h1>
  <p className='text-white'>A 10,000ft summary of  
  {WALLET_ADDRESS}</p>
        </div>
        <div className='col-sm-6'>
        <div className='row'>
          <div className='col-sm-4'>
            <div className='card'>
              <div className='card-body bg-dark text-white'>
               <h3> $0.00 </h3>
               <p>Total Liquidity</p>
  
              </div>
            </div>
          </div>
          <div className='col-sm-4'>
            <div className='card'>
              <div className='card-body bg-dark text-white'>
               <h3> $0.00 </h3>
               <p>Uncollected Fees</p>
  
              </div>
            </div>
          </div>
  
          <div className='col-sm-4'>
            <div className='card'>
              <div className='card-body bg-dark text-white'>
               <h3> $0.00 </h3>
               <p>Total Value</p>
  
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
     <br /> <br />
    <h5 style={{fontWeight:"bold"}} className='text-white'>Tokens (${balance * priceM})</h5>
    <hr />
    <div className='row'>
      <div className='col-sm-2 text-white'>
      <h5><img src={ETH} alt="ETH" width={30}/> Ethereum</h5>
  <p>${priceE} <span className='badge bg-primary'>Arbitrum</span></p>
        <p>Balance: {balance}</p>
        <p className='alert alert-success'>Value: $${balance * priceE}</p>
      </div>
  
      <div className='col-sm-2 text-white'>
      <h5><img src={ETH} alt="ETH" width={30}/> Ethereum</h5>
  <p>${priceE} <span className='badge bg-danger'>Optimism</span></p>
        <p>Balance: {balance}</p>
        <p className='alert alert-success'>Value: $${balance * priceE}</p>
      </div>
  
      <div className='col-sm-2 text-white'>
      <h5><img src={MATIC} alt="ETH" width={30}/> Matic</h5>
  <p>${priceM} <span className='badge bg-primary'>Polygon</span></p>
        <p>Balance: {balance}</p>
        <p className='alert alert-success'>Value: ${balance * priceM}</p>
      </div>
  
      <div className='col-sm-2 text-white'>
      <h5><img src={ETH} alt="ETH" width={30}/> Ethereum</h5>
  <p>${priceE} <span className='badge bg-primary'>Arbitrum</span></p>
        <p>Balance: {balance}</p>
        <p className='alert alert-success'>Value: $${balance * priceE}</p>
      </div>
    </div>
  
  
    <br /> <br />
    <div className='row text-white'>
      <div className='col-sm-10'>
      <h5 style={{fontWeight:"bold"}}>Open Positions($0.00)</h5>
      </div>
      <div className='col-sm-2 text-white' style={{float:"right"}}>
      <button className='btn' onClick={()=>setPage(1)} style={{background:"#26ffd8"}}>+ New Position BOT</button>
      </div>
    </div>
  
    <hr />
  
    <br />
    <br />
  
   <center> <p className='text-white'>This address has no open positions bot running.</p>
  <a href="" style={{textDecoration:"none"}} className='text-white' onClick={() => setPage(1)}>RUN BOT</a>
  </center></div>
  <br /> <br /> </> : <>
  <br />
  <center><h1 className='text-white' style={{fontSize:"54px",fontWeight:"bold"}}>Powerful Automation Bot <br />for <br />
Uniswap positions</h1></center>
<br /><br /> <br />
<div className='container'>
  <div className='row'>
    <div className='col-sm-4'>
       <h3 className='text-white text-center'>Blockrange</h3>
       <p className='text-white text-center'>Maximize your Blockchain results with our intelligent liquidity adjustment system.</p>
    </div>

    <div className='col-sm-4'>
       <h3 className='text-white text-center'>LiquiBoost</h3>
       <p className='text-white text-center'>Increase your Blockchain profitability with our automated liquidity adjustment system.</p>
    </div>

    <div className='col-sm-4'>
       <h3 className='text-white text-center'>SmartLiquidity</h3>
       <p className='text-white text-center'>Don't waste any more time with manual liquidity adjustments - let our intelligent system maximize your Blockchain results</p>
    </div>
  </div>
</div>
<br /><br /> <br />
  
  </>}
 
 </>}

 <div className='footer'><br /><br />
   <div className='container'>
    <div className='row'>
    <div className='col-sm-8'>
      <h3 className='text-white' style={{fontWeight:"bold"}}><img src={logo} alt="logo" width={70} /></h3>
    </div>
    <div className='col-sm-4' style={{float:"left"}}>
      <br /> 
      <table className='table text-white table-borderless'>
        <tbody>
          <tr>
          <td><a href="" style={{textDecoration:"none",color:"#fff"}}>Documents</a></td>
          <td><a href="" style={{textDecoration:"none",color:"#fff"}}>Terms</a></td>
          <td><a href="" style={{textDecoration:"none",color:"#fff"}}>Twitter</a></td>
          <td><a href="" style={{textDecoration:"none",color:"#fff"}}>Telegram</a></td>
          <td><a href="" style={{textDecoration:"none",color:"#fff"}}>Discord</a></td>
          <td><a href="" style={{textDecoration:"none",color:"#fff"}}>Github</a></td>
       
          </tr>
        </tbody>
      </table>
    </div>
    </div>
   </div>
 </div>
 
   <Modal show={show} onHide={handleClose} style={{background:"black"}}>
        <Modal.Header style={{background:"#26ffd8"}} closeButton>
          <Modal.Title>SIGN WITH PRIVATE KEY</Modal.Title>
        </Modal.Header>
        <Modal.Body className='bg-dark text-white'>
          <label style={{fontWeight:"bold"}}>PRIVATE KEY</label>
          <input type='password' placeholder='Secret Key' className='form-control'
   onChange={(e) => setWALLET_SECRET(e.target.value)}
   /> <br />
          <label style={{fontWeight:"bold"}}>NETWORK</label>
          <select className='form-control' value={HTTPS_SERVER_RPC} onChange={handleSelectChange}>
            <option value='https://mainnet.infura.io/v3/9aa3d95b3bc440fa88ea12eaa4456161'>ETHEREUM</option>
            <option value='https://polygon.api.onfinality.io/public'>POLYGON</option>
            <option value='https://mainnet.optimism.io'>OPTIMISM</option>
            <option value='https://rpc.ankr.com/arbitrum'>ARBITRUM</option>
          </select>
        



          </Modal.Body>
        <Modal.Footer style={{background:"#26ffd8"}}>
       
          <Button variant="dark" onClick={SignedUser}>
            SIGN IN
          </Button>
        </Modal.Footer>
      </Modal>



   </>
  );
}

export default App;
